import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"

class PrivacyPolicyPage extends React.Component {


    render() {

        return (
            <LayoutInfo>
                <SEO title="Kjøpsvilkår"
                  description="Kjøpsvilkårene til Leirskoledagboken AS" />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Kjøpsvilkår</h1>

                        <div>

                            <p>For å bestille produkter hos oss må bruker være over 18 år. Ved å godkjenne vilkår har du bekreftet at du er over 18 år.</p>
                            <dl>
                                <dt>Leveranse</dt>
                                <dd>
                                    <p>Leveransetiden er normalt mellom 2-4 uker fra leirskoleoppholdets slutt. </p>
                                </dd>
                                <dt>Produksjon av ordre bestilt i ferier</dt>
                                <dd>
                                    <p>Ordre bestilt i juli vil på grunn av sommerferie bli produsert i starten av august slik at de blir levert da de fleste er hjemme fra sin egen ferie. Tilsvarende gjelder ordre som blir bestilt rett før eller i juleferie og påskeferie.</p>
                                </dd>
                            </dl>
                            <dt>Angrerett</dt>
                            <dd>
                                <p>Det er 30 dagers angrerett ved bestilling av Leirskoledagboken. Produktet returneres fraktfritt ved å snu infoarket som medfølger boken, tape det på boken slik at returadressen synes og legge i nærmeste røde postkasse. Kreditering av faktura forutsetter at returen inneholder en eller annen form for kunde-ID.</p>
                                <p>Se Forbrukerrådet <a href="http://www.forbrukerradet.no/wp-content/uploads/2015/10/Veileder-til-angrerettloven.pdf">sin veileder til angrerettloven</a>.</p>
                            </dd>
                            <dt>Reklamasjon</dt>
                            <dd>
                                <p>
                                    Reklamasjon gjelder om varen er delvis eller helt skadet i distribusjon eller har tekniske feil eller mangler. Gjelder også hvis produktet ikke har kommet fram i posten. Ved reklamasjon skal alltid kundeservice hos Leirskoledagboken AS kontaktes via e-post innen 30 dager etter fakturadato. Hvis denne prosedyren ikke blir fulgt, har Leirskoledagboken AS rett til å kreve full betaling for produktet.
                                </p>
                            </dd>
                            <dt>Betaling</dt>
                            <dd>
                                <p>
                                    Faktura sendes på epost ved utsending av ferdig produkt. Betalingsfrist for faktura er 30 dager. Ved purring påløper en kostnad på kr 35,- ihht Inkassoloven. Ved varsel om inkasso påløper en kostnad på 70,- ihht Inkassoloven. Krav om inkasso vil overføres til vår partner, Alektum. 
                                </p>
                            </dd>
                            
                        </div>
                        
                        <p>Kjøpsvilkårene ble sist oppdatert 12.08.2021.</p>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default PrivacyPolicyPage
